body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  @font-face {
    font-family: 'Major Mono Display Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../../../public/fonts/MajorMonoDisplay-Regular.woff') format('woff');
    }
  @font-face {
    font-family: 'Fira Code Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../../../public/fonts/FiraCode-Regular.woff2') format('woff2');
    }

.ant-collapse-header:focus, .ant-modal-close:focus, button:focus {
  border: none;
  box-shadow: inset 0px 0px 0px 2px #1890ff;
}
a:focus {
  text-decoration: underline;
}